import moment from 'moment-timezone'

export default class Calendar {
  constructor(calendar) {
    this.id = calendar.id
    this.owner_id = calendar.owner_id
    this.company_id = calendar.company_id
    this.start_date = moment.utc(calendar.start_date)
    this.end_date = moment.utc(calendar.end_date)
    this.hidden = calendar.hidden
    this.created_at = moment.utc(calendar.created_at)
    this.updated_at = moment.utc(calendar.updated_at)
    this.deleted_at = calendar.deleted_at
      ? moment.utc(calendar.deleted_at)
      : null
  }

  isPastCalendar() {
    return this.end_date.isBefore(moment().utc(), 'day')
  }

  isFutureCalendar() {
    return this.start_date.isAfter(moment().utc(), 'day')
  }
}
