export default class SupportedMimeType {
  static lookup = [
    { mimeType: 'application/acrobat', extensions: ['pdf'] },
    { mimeType: 'application/bdoc', extensions: ['bdoc'] },
    { mimeType: 'application/coreldraw', extensions: ['cdr'] },
    { mimeType: 'application/csv', extensions: ['csv'] },
    { mimeType: 'application/docbook+xml', extensions: ['dbk', 'docbook'] },
    { mimeType: 'application/epub+zip', extensions: ['epub'] },
    { mimeType: 'application/illustrator', extensions: ['ai'] },
    { mimeType: 'application/inkml+xml', extensions: ['ink', 'inkml'] },
    { mimeType: 'application/json', extensions: ['json', 'map'] },
    { mimeType: 'application/ld+json', extensions: ['jsonld'] },
    {
      mimeType: 'application/lotus123',
      extensions: ['123', 'wk1', 'wk3', 'wk4', 'wks'],
    },
    { mimeType: 'application/m3u', extensions: ['m3u', 'm3u8', 'vlc'] },
    { mimeType: 'application/mp4', extensions: ['mp4s', 'm4p'] },
    {
      mimeType: 'application/msexcel',
      extensions: ['xls', 'xlc', 'xll', 'xlm', 'xlw', 'xla', 'xlt', 'xld'],
    },
    {
      mimeType: 'application/mspowerpoint',
      extensions: ['ppz', 'ppt', 'pps', 'pot'],
    },
    { mimeType: 'application/msword', extensions: ['doc', 'dot'] },
    { mimeType: 'application/msword-template', extensions: ['dot'] },
    { mimeType: 'application/nappdf', extensions: ['pdf'] },
    {
      mimeType: 'application/onenote',
      extensions: ['onetoc', 'onetoc2', 'onetmp', 'onepkg'],
    },
    { mimeType: 'application/pdf', extensions: ['pdf'] },
    { mimeType: 'application/photoshop', extensions: ['psd'] },
    { mimeType: 'application/postscript', extensions: ['ai', 'eps', 'ps'] },
    {
      mimeType: 'application/powerpoint',
      extensions: ['ppz', 'ppt', 'pps', 'pot'],
    },
    { mimeType: 'application/schema+json', extensions: ['json'] },
    { mimeType: 'application/toml', extensions: ['toml'] },
    { mimeType: 'application/vnd.adobe.illustrator', extensions: ['ai'] },
    { mimeType: 'application/vnd.amazon.ebook', extensions: ['azw'] },
    {
      mimeType: 'application/vnd.amazon.mobi8-ebook',
      extensions: ['azw3', 'kfx'],
    },
    {
      mimeType: 'application/vnd.apple.keynote',
      extensions: ['key', 'keynote'],
    },
    { mimeType: 'application/vnd.apple.mpegurl', extensions: ['m3u8', 'm3u'] },
    { mimeType: 'application/vnd.apple.numbers', extensions: ['numbers'] },
    { mimeType: 'application/vnd.apple.pages', extensions: ['pages'] },
    { mimeType: 'application/vnd.balsamiq.bmml+xml', extensions: ['bmml'] },
    { mimeType: 'application/vnd.comicbook+zip', extensions: ['cbz'] },
    { mimeType: 'application/vnd.comicbook-rar', extensions: ['cbr'] },
    { mimeType: 'application/vnd.corel-draw', extensions: ['cdr'] },
    { mimeType: 'application/vnd.google-apps.document', extensions: ['gdoc'] },
    {
      mimeType: 'application/vnd.google-apps.presentation',
      extensions: ['gslides'],
    },
    {
      mimeType: 'application/vnd.google-apps.spreadsheet',
      extensions: ['gsheet'],
    },
    { mimeType: 'application/vnd.kde.karbon', extensions: ['karbon'] },
    { mimeType: 'application/vnd.kde.kchart', extensions: ['chrt'] },
    { mimeType: 'application/vnd.kde.kformula', extensions: ['kfo'] },
    { mimeType: 'application/vnd.kde.kivio', extensions: ['flw'] },
    { mimeType: 'application/vnd.kde.kontour', extensions: ['kon'] },
    { mimeType: 'application/vnd.kde.kpresenter', extensions: ['kpr', 'kpt'] },
    { mimeType: 'application/vnd.kde.kspread', extensions: ['ksp'] },
    { mimeType: 'application/vnd.kde.kword', extensions: ['kwd', 'kwt'] },
    {
      mimeType: 'application/vnd.lotus-1-2-3',
      extensions: ['123', 'wk1', 'wk3', 'wk4', 'wks'],
    },
    { mimeType: 'application/vnd.lotus-notes', extensions: ['nsf'] },
    { mimeType: 'application/vnd.lotus-wordpro', extensions: ['lwp'] },
    {
      mimeType: 'application/vnd.ms-excel',
      extensions: ['xls', 'xlm', 'xla', 'xlc', 'xlt', 'xlw', 'xll', 'xld'],
    },
    { mimeType: 'application/vnd.ms-htmlhelp', extensions: ['chm'] },
    { mimeType: 'application/vnd.ms-outlook', extensions: ['msg'] },
    {
      mimeType: 'application/vnd.ms-powerpoint',
      extensions: ['ppt', 'pps', 'pot', 'ppz'],
    },
    { mimeType: 'application/vnd.ms-project', extensions: ['mpp', 'mpt'] },
    { mimeType: 'application/vnd.ms-publisher', extensions: ['pub'] },
    {
      mimeType: 'application/vnd.ms-visio.drawing.main+xml',
      extensions: ['vsdx'],
    },
    {
      mimeType: 'application/vnd.ms-visio.stencil.main+xml',
      extensions: ['vssx'],
    },
    {
      mimeType: 'application/vnd.ms-visio.template.main+xml',
      extensions: ['vstx'],
    },
    { mimeType: 'application/vnd.ms-word', extensions: ['doc'] },
    {
      mimeType: 'application/vnd.ms-works',
      extensions: ['wps', 'wks', 'wcm', 'wdb', 'xlr'],
    },
    {
      mimeType: 'application/vnd.oasis.docbook+xml',
      extensions: ['dbk', 'docbook'],
    },
    {
      mimeType: 'application/vnd.oasis.opendocument.chart',
      extensions: ['odc'],
    },
    {
      mimeType: 'application/vnd.oasis.opendocument.chart-template',
      extensions: ['otc'],
    },
    {
      mimeType: 'application/vnd.oasis.opendocument.database',
      extensions: ['odb'],
    },
    {
      mimeType: 'application/vnd.oasis.opendocument.formula',
      extensions: ['odf'],
    },
    {
      mimeType: 'application/vnd.oasis.opendocument.formula-template',
      extensions: ['odft', 'otf'],
    },
    {
      mimeType: 'application/vnd.oasis.opendocument.graphics',
      extensions: ['odg'],
    },
    {
      mimeType: 'application/vnd.oasis.opendocument.graphics-flat-xml',
      extensions: ['fodg'],
    },
    {
      mimeType: 'application/vnd.oasis.opendocument.graphics-template',
      extensions: ['otg'],
    },
    {
      mimeType: 'application/vnd.oasis.opendocument.image',
      extensions: ['odi'],
    },
    {
      mimeType: 'application/vnd.oasis.opendocument.image-template',
      extensions: ['oti'],
    },
    {
      mimeType: 'application/vnd.oasis.opendocument.presentation',
      extensions: ['odp'],
    },
    {
      mimeType: 'application/vnd.oasis.opendocument.presentation-flat-xml',
      extensions: ['fodp'],
    },
    {
      mimeType: 'application/vnd.oasis.opendocument.presentation-template',
      extensions: ['otp'],
    },
    {
      mimeType: 'application/vnd.oasis.opendocument.spreadsheet',
      extensions: ['ods'],
    },
    {
      mimeType: 'application/vnd.oasis.opendocument.spreadsheet-flat-xml',
      extensions: ['fods'],
    },
    {
      mimeType: 'application/vnd.oasis.opendocument.spreadsheet-template',
      extensions: ['ots'],
    },
    {
      mimeType: 'application/vnd.oasis.opendocument.text',
      extensions: ['odt'],
    },
    {
      mimeType: 'application/vnd.oasis.opendocument.text-flat-xml',
      extensions: ['fodt'],
    },
    {
      mimeType: 'application/vnd.oasis.opendocument.text-master',
      extensions: ['odm'],
    },
    {
      mimeType: 'application/vnd.oasis.opendocument.text-template',
      extensions: ['ott'],
    },
    {
      mimeType: 'application/vnd.oasis.opendocument.text-web',
      extensions: ['oth'],
    },
    {
      mimeType:
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      extensions: ['pptx'],
    },
    {
      mimeType:
        'application/vnd.openxmlformats-officedocument.presentationml.slide',
      extensions: ['sldx'],
    },
    {
      mimeType:
        'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
      extensions: ['ppsx'],
    },
    {
      mimeType:
        'application/vnd.openxmlformats-officedocument.presentationml.template',
      extensions: ['potx'],
    },
    {
      mimeType:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      extensions: ['xlsx'],
    },
    {
      mimeType:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
      extensions: ['xltx'],
    },
    {
      mimeType:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      extensions: ['docx'],
    },
    {
      mimeType:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
      extensions: ['dotx'],
    },
    { mimeType: 'application/vnd.stardivision.calc', extensions: ['sdc'] },
    { mimeType: 'application/vnd.stardivision.chart', extensions: ['sds'] },
    { mimeType: 'application/vnd.stardivision.draw', extensions: ['sda'] },
    {
      mimeType: 'application/vnd.stardivision.impress',
      extensions: ['sdd', 'sdp'],
    },
    { mimeType: 'application/vnd.stardivision.mail', extensions: ['smd'] },
    { mimeType: 'application/vnd.stardivision.math', extensions: ['smf'] },
    {
      mimeType: 'application/vnd.stardivision.writer',
      extensions: ['sdw', 'vor', 'sgl'],
    },
    { mimeType: 'application/vnd.sun.xml.calc', extensions: ['sxc'] },
    { mimeType: 'application/vnd.sun.xml.calc.template', extensions: ['stc'] },
    { mimeType: 'application/vnd.sun.xml.draw', extensions: ['sxd'] },
    { mimeType: 'application/vnd.sun.xml.draw.template', extensions: ['std'] },
    { mimeType: 'application/vnd.sun.xml.impress', extensions: ['sxi'] },
    {
      mimeType: 'application/vnd.sun.xml.impress.template',
      extensions: ['sti'],
    },
    { mimeType: 'application/vnd.sun.xml.math', extensions: ['sxm'] },
    { mimeType: 'application/vnd.sun.xml.writer', extensions: ['sxw'] },
    {
      mimeType: 'application/vnd.sun.xml.writer.template',
      extensions: ['stw'],
    },
    {
      mimeType: 'application/vnd.visio',
      extensions: ['vsd', 'vst', 'vss', 'vsw'],
    },
    {
      mimeType: 'application/vnd.wordperfect',
      extensions: ['wpd', 'wp', 'wp4', 'wp5', 'wp6', 'wpp'],
    },
    {
      mimeType: 'application/wordperfect',
      extensions: ['wp', 'wp4', 'wp5', 'wp6', 'wpd', 'wpp'],
    },
    { mimeType: 'application/x-applix-spreadsheet', extensions: ['as'] },
    { mimeType: 'application/x-applix-word', extensions: ['aw'] },
    {
      mimeType: 'application/x-blender',
      extensions: ['blender', 'blend', 'BLEND'],
    },
    {
      mimeType: 'application/x-gnucash',
      extensions: ['gnucash', 'gnc', 'xac'],
    },
    { mimeType: 'application/x-gnumeric', extensions: ['gnumeric'] },
    {
      mimeType: 'application/x-gnuplot',
      extensions: ['gp', 'gplt', 'gnuplot'],
    },
    { mimeType: 'application/x-gzpdf', extensions: ['pdf.gz'] },
    { mimeType: 'application/x-iwork-keynote-sffkey', extensions: ['key'] },
    {
      mimeType: 'application/x-iwork-numbers-sffnumbers',
      extensions: ['numbers'],
    },
    { mimeType: 'application/x-iwork-pages-sffpages', extensions: ['pages'] },
    { mimeType: 'application/x-karbon', extensions: ['karbon'] },
    { mimeType: 'application/x-kchart', extensions: ['chrt'] },
    { mimeType: 'application/x-kformula', extensions: ['kfo'] },
    { mimeType: 'application/x-killustrator', extensions: ['kil'] },
    { mimeType: 'application/x-kivio', extensions: ['flw'] },
    { mimeType: 'application/x-kontour', extensions: ['kon'] },
    { mimeType: 'application/x-kpresenter', extensions: ['kpr', 'kpt'] },
    { mimeType: 'application/x-krita', extensions: ['kra', 'krz'] },
    { mimeType: 'application/x-kspread', extensions: ['ksp'] },
    { mimeType: 'application/x-kword', extensions: ['kwd', 'kwt'] },
    { mimeType: 'application/x-latex', extensions: ['latex'] },
    {
      mimeType: 'application/x-msexcel',
      extensions: ['xls', 'xlc', 'xll', 'xlm', 'xlw', 'xla', 'xlt', 'xld'],
    },
    {
      mimeType: 'application/x-mspowerpoint',
      extensions: ['ppz', 'ppt', 'pps', 'pot'],
    },
    { mimeType: 'application/x-msword', extensions: ['doc'] },
    { mimeType: 'application/x-mswrite', extensions: ['wri'] },
    { mimeType: 'application/x-pdf', extensions: ['pdf'] },
    { mimeType: 'application/x-photoshop', extensions: ['psd'] },
    {
      mimeType: 'application/x-wordperfect',
      extensions: ['wp', 'wp4', 'wp5', 'wp6', 'wpd', 'wpp'],
    },
    {
      mimeType: 'application/xml',
      extensions: ['xml', 'xsl', 'xsd', 'rng', 'xbl'],
    },
    { mimeType: 'audio/3gpp', extensions: ['3gpp', '3gp', '3ga'] },
    { mimeType: 'audio/3gpp-encrypted', extensions: ['3gp', '3gpp', '3ga'] },
    { mimeType: 'audio/3gpp2', extensions: ['3g2', '3gp2', '3gpp2'] },
    { mimeType: 'audio/aac', extensions: ['aac', 'adts', 'ass'] },
    { mimeType: 'audio/ac3', extensions: ['ac3'] },
    { mimeType: 'audio/adpcm', extensions: ['adp'] },
    { mimeType: 'audio/amr', extensions: ['amr'] },
    { mimeType: 'audio/amr-encrypted', extensions: ['amr'] },
    { mimeType: 'audio/amr-wb', extensions: ['awb'] },
    { mimeType: 'audio/amr-wb-encrypted', extensions: ['awb'] },
    { mimeType: 'audio/annodex', extensions: ['axa'] },
    { mimeType: 'audio/basic', extensions: ['au', 'snd'] },
    { mimeType: 'audio/flac', extensions: ['flac'] },
    { mimeType: 'audio/imelody', extensions: ['imy', 'ime'] },
    { mimeType: 'audio/m3u', extensions: ['m3u', 'm3u8', 'vlc'] },
    { mimeType: 'audio/m4a', extensions: ['m4a', 'f4a'] },
    { mimeType: 'audio/midi', extensions: ['mid', 'midi', 'kar', 'rmi'] },
    { mimeType: 'audio/mobile-xmf', extensions: ['mxmf', 'xmf'] },
    { mimeType: 'audio/mp2', extensions: ['mp2'] },
    { mimeType: 'audio/mp3', extensions: ['mp3', 'mpga'] },
    { mimeType: 'audio/mp4', extensions: ['m4a', 'mp4a', 'f4a'] },
    {
      mimeType: 'audio/mpeg',
      extensions: ['mp3', 'mpga', 'mp2', 'mp2a', 'm2a', 'm3a'],
    },
    { mimeType: 'audio/mpegurl', extensions: ['m3u', 'm3u8', 'vlc'] },
    { mimeType: 'audio/ogg', extensions: ['ogg', 'oga', 'spx', 'opus'] },
    { mimeType: 'audio/prs.sid', extensions: ['sid', 'psid'] },
    { mimeType: 'audio/s3m', extensions: ['s3m'] },
    { mimeType: 'audio/scpls', extensions: ['pls'] },
    { mimeType: 'audio/silk', extensions: ['sil'] },
    { mimeType: 'audio/tta', extensions: ['tta'] },
    { mimeType: 'audio/usac', extensions: ['loas', 'xhe'] },
    { mimeType: 'audio/vnd.audible', extensions: ['aa', 'aax'] },
    { mimeType: 'audio/vnd.audible.aax', extensions: ['aax'] },
    { mimeType: 'audio/vnd.dece.audio', extensions: ['uva', 'uvva'] },
    { mimeType: 'audio/vnd.digital-winds', extensions: ['eol'] },
    { mimeType: 'audio/vnd.dra', extensions: ['dra'] },
    { mimeType: 'audio/vnd.dts', extensions: ['dts'] },
    { mimeType: 'audio/vnd.dts.hd', extensions: ['dtshd'] },
    { mimeType: 'audio/vnd.lucent.voice', extensions: ['lvp'] },
    { mimeType: 'audio/vnd.m-realaudio', extensions: ['ra', 'rax'] },
    { mimeType: 'audio/vnd.ms-playready.media.pya', extensions: ['pya'] },
    { mimeType: 'audio/vnd.nuera.ecelp4800', extensions: ['ecelp4800'] },
    { mimeType: 'audio/vnd.nuera.ecelp7470', extensions: ['ecelp7470'] },
    { mimeType: 'audio/vnd.nuera.ecelp9600', extensions: ['ecelp9600'] },
    { mimeType: 'audio/vnd.rip', extensions: ['rip'] },
    { mimeType: 'audio/vnd.rn-realaudio', extensions: ['ra', 'rax'] },
    { mimeType: 'audio/vnd.wave', extensions: ['wav'] },
    { mimeType: 'audio/vorbis', extensions: ['oga', 'ogg'] },
    { mimeType: 'audio/wav', extensions: ['wav'] },
    { mimeType: 'audio/wave', extensions: ['wav'] },
    { mimeType: 'audio/webm', extensions: ['weba'] },
    { mimeType: 'audio/wma', extensions: ['wma'] },
    { mimeType: 'audio/x-aac', extensions: ['aac', 'adts', 'ass'] },
    { mimeType: 'audio/x-aifc', extensions: ['aifc', 'aiffc'] },
    { mimeType: 'audio/x-aiff', extensions: ['aif', 'aiff', 'aifc'] },
    { mimeType: 'audio/x-aiffc', extensions: ['aifc', 'aiffc'] },
    { mimeType: 'audio/x-amzxml', extensions: ['amz'] },
    { mimeType: 'audio/x-annodex', extensions: ['axa'] },
    { mimeType: 'audio/x-ape', extensions: ['ape'] },
    { mimeType: 'audio/x-caf', extensions: ['caf'] },
    { mimeType: 'audio/x-dts', extensions: ['dts'] },
    { mimeType: 'audio/x-dtshd', extensions: ['dtshd'] },
    { mimeType: 'audio/x-flac', extensions: ['flac'] },
    { mimeType: 'audio/x-flac+ogg', extensions: ['oga', 'ogg'] },
    { mimeType: 'audio/x-gsm', extensions: ['gsm'] },
    { mimeType: 'audio/x-hx-aac-adts', extensions: ['aac', 'adts', 'ass'] },
    { mimeType: 'audio/x-imelody', extensions: ['imy', 'ime'] },
    { mimeType: 'audio/x-iriver-pla', extensions: ['pla'] },
    { mimeType: 'audio/x-it', extensions: ['it'] },
    { mimeType: 'audio/x-m3u', extensions: ['m3u', 'm3u8', 'vlc'] },
    { mimeType: 'audio/x-m4a', extensions: ['m4a', 'f4a'] },
    { mimeType: 'audio/x-m4b', extensions: ['m4b', 'f4b'] },
    { mimeType: 'audio/x-m4r', extensions: ['m4r'] },
    { mimeType: 'audio/x-matroska', extensions: ['mka'] },
    { mimeType: 'audio/x-midi', extensions: ['mid', 'midi', 'kar'] },
    { mimeType: 'audio/x-minipsf', extensions: ['minipsf'] },
    { mimeType: 'audio/x-mo3', extensions: ['mo3'] },
    {
      mimeType: 'audio/x-mod',
      extensions: ['mod', 'ult', 'uni', 'm15', 'mtm', '669', 'med'],
    },
    { mimeType: 'audio/x-mp2', extensions: ['mp2'] },
    { mimeType: 'audio/x-mp3', extensions: ['mp3', 'mpga'] },
    { mimeType: 'audio/x-mp3-playlist', extensions: ['m3u', 'm3u8', 'vlc'] },
    { mimeType: 'audio/x-mpeg', extensions: ['mp3', 'mpga'] },
    { mimeType: 'audio/x-mpegurl', extensions: ['m3u', 'm3u8', 'vlc'] },
    { mimeType: 'audio/x-mpg', extensions: ['mp3', 'mpga'] },
    { mimeType: 'audio/x-ms-asx', extensions: ['asx', 'wax', 'wvx', 'wmx'] },
    { mimeType: 'audio/x-ms-wax', extensions: ['wax'] },
    { mimeType: 'audio/x-ms-wma', extensions: ['wma'] },
    { mimeType: 'audio/x-ms-wmv', extensions: ['wmv'] },
    { mimeType: 'audio/x-musepack', extensions: ['mpc', 'mpp', 'mp+'] },
    { mimeType: 'audio/x-ogg', extensions: ['oga', 'ogg', 'opus'] },
    { mimeType: 'audio/x-oggflac', extensions: ['oga', 'ogg'] },
    { mimeType: 'audio/x-opus+ogg', extensions: ['opus'] },
    { mimeType: 'audio/x-pn-audibleaudio', extensions: ['aa', 'aax'] },
    { mimeType: 'audio/x-pn-realaudio', extensions: ['ram', 'ra', 'rax'] },
    { mimeType: 'audio/x-pn-realaudio-plugin', extensions: ['rmp'] },
    { mimeType: 'audio/x-psf', extensions: ['psf'] },
    { mimeType: 'audio/x-psflib', extensions: ['psflib'] },
    { mimeType: 'audio/x-realaudio', extensions: ['ra'] },
    { mimeType: 'audio/x-rn-3gpp-amr', extensions: ['3gp', '3gpp', '3ga'] },
    {
      mimeType: 'audio/x-rn-3gpp-amr-encrypted',
      extensions: ['3gp', '3gpp', '3ga'],
    },
    { mimeType: 'audio/x-rn-3gpp-amr-wb', extensions: ['3gp', '3gpp', '3ga'] },
    {
      mimeType: 'audio/x-rn-3gpp-amr-wb-encrypted',
      extensions: ['3gp', '3gpp', '3ga'],
    },
    { mimeType: 'audio/x-s3m', extensions: ['s3m'] },
    { mimeType: 'audio/x-scpls', extensions: ['pls'] },
    { mimeType: 'audio/x-shorten', extensions: ['shn'] },
    { mimeType: 'audio/x-speex', extensions: ['spx'] },
    { mimeType: 'audio/x-speex+ogg', extensions: ['oga', 'ogg', 'spx'] },
    { mimeType: 'audio/x-stm', extensions: ['stm'] },
    { mimeType: 'audio/x-tta', extensions: ['tta'] },
    { mimeType: 'audio/x-voc', extensions: ['voc'] },
    { mimeType: 'audio/x-vorbis', extensions: ['oga', 'ogg'] },
    { mimeType: 'audio/x-vorbis+ogg', extensions: ['oga', 'ogg'] },
    { mimeType: 'audio/x-wav', extensions: ['wav'] },
    { mimeType: 'audio/x-wavpack', extensions: ['wv', 'wvp'] },
    { mimeType: 'audio/x-wavpack-correction', extensions: ['wvc'] },
    { mimeType: 'audio/x-xi', extensions: ['xi'] },
    { mimeType: 'audio/x-xm', extensions: ['xm'] },
    { mimeType: 'audio/x-xmf', extensions: ['xmf'] },
    { mimeType: 'audio/xm', extensions: ['xm'] },
    { mimeType: 'audio/xmf', extensions: ['xmf'] },
    { mimeType: 'image/aces', extensions: ['exr'] },
    { mimeType: 'image/apng', extensions: ['apng'] },
    { mimeType: 'image/astc', extensions: ['astc'] },
    { mimeType: 'image/avif', extensions: ['avif', 'avifs'] },
    { mimeType: 'image/avif-sequence', extensions: ['avif', 'avifs'] },
    { mimeType: 'image/bmp', extensions: ['bmp', 'dib'] },
    { mimeType: 'image/cdr', extensions: ['cdr'] },
    { mimeType: 'image/cgm', extensions: ['cgm'] },
    { mimeType: 'image/dicom-rle', extensions: ['drle'] },
    { mimeType: 'image/emf', extensions: ['emf'] },
    { mimeType: 'image/fax-g3', extensions: ['g3'] },
    { mimeType: 'image/fits', extensions: ['fits'] },
    { mimeType: 'image/g3fax', extensions: ['g3'] },
    { mimeType: 'image/gif', extensions: ['gif'] },
    { mimeType: 'image/heic', extensions: ['heic', 'heif'] },
    { mimeType: 'image/heic-sequence', extensions: ['heics', 'heic', 'heif'] },
    { mimeType: 'image/heif', extensions: ['heif', 'heic'] },
    { mimeType: 'image/heif-sequence', extensions: ['heifs', 'heic', 'heif'] },
    { mimeType: 'image/hej2k', extensions: ['hej2'] },
    { mimeType: 'image/hsj2', extensions: ['hsj2'] },
    { mimeType: 'image/ico', extensions: ['ico'] },
    { mimeType: 'image/icon', extensions: ['ico'] },
    { mimeType: 'image/ief', extensions: ['ief'] },
    { mimeType: 'image/jls', extensions: ['jls'] },
    { mimeType: 'image/jp2', extensions: ['jp2', 'jpg2'] },
    { mimeType: 'image/jpeg', extensions: ['jpg', 'jpeg', 'jpe'] },
    { mimeType: 'image/jpeg2000', extensions: ['jp2', 'jpg2'] },
    { mimeType: 'image/jpeg2000-image', extensions: ['jp2', 'jpg2'] },
    { mimeType: 'image/jph', extensions: ['jph'] },
    { mimeType: 'image/jphc', extensions: ['jhc'] },
    { mimeType: 'image/jpm', extensions: ['jpm', 'jpgm'] },
    { mimeType: 'image/jpx', extensions: ['jpx', 'jpf'] },
    { mimeType: 'image/jxl', extensions: ['jxl'] },
    { mimeType: 'image/jxr', extensions: ['jxr'] },
    { mimeType: 'image/jxra', extensions: ['jxra'] },
    { mimeType: 'image/jxrs', extensions: ['jxrs'] },
    { mimeType: 'image/jxs', extensions: ['jxs'] },
    { mimeType: 'image/jxsc', extensions: ['jxsc'] },
    { mimeType: 'image/jxsi', extensions: ['jxsi'] },
    { mimeType: 'image/jxss', extensions: ['jxss'] },
    { mimeType: 'image/ktx', extensions: ['ktx'] },
    { mimeType: 'image/ktx2', extensions: ['ktx2'] },
    { mimeType: 'image/openraster', extensions: ['ora'] },
    { mimeType: 'image/pdf', extensions: ['pdf'] },
    { mimeType: 'image/photoshop', extensions: ['psd'] },
    { mimeType: 'image/pjpeg', extensions: ['jpg', 'jpeg', 'jpe'] },
    { mimeType: 'image/png', extensions: ['png'] },
    { mimeType: 'image/prs.btif', extensions: ['btif'] },
    { mimeType: 'image/prs.pti', extensions: ['pti'] },
    { mimeType: 'image/psd', extensions: ['psd'] },
    { mimeType: 'image/rle', extensions: ['rle'] },
    { mimeType: 'image/sgi', extensions: ['sgi'] },
    { mimeType: 'image/svg', extensions: ['svg'] },
    { mimeType: 'image/svg+xml', extensions: ['svg', 'svgz'] },
    { mimeType: 'image/svg+xml-compressed', extensions: ['svgz'] },
    { mimeType: 'image/t38', extensions: ['t38'] },
    {
      mimeType: 'image/targa',
      extensions: ['tga', 'icb', 'tpic', 'vda', 'vst'],
    },
    { mimeType: 'image/tga', extensions: ['tga', 'icb', 'tpic', 'vda', 'vst'] },
    { mimeType: 'image/tiff', extensions: ['tif', 'tiff'] },
    { mimeType: 'image/tiff-fx', extensions: ['tfx'] },
    { mimeType: 'image/vnd.adobe.photoshop', extensions: ['psd'] },
    { mimeType: 'image/vnd.airzip.accelerator.azv', extensions: ['azv'] },
    {
      mimeType: 'image/vnd.dece.graphic',
      extensions: ['uvi', 'uvvi', 'uvg', 'uvvg'],
    },
    { mimeType: 'image/vnd.djvu', extensions: ['djvu', 'djv'] },
    { mimeType: 'image/vnd.djvu+multipage', extensions: ['djvu', 'djv'] },
    { mimeType: 'image/vnd.dvb.subtitle', extensions: ['sub'] },
    { mimeType: 'image/vnd.dwg', extensions: ['dwg'] },
    { mimeType: 'image/vnd.dxf', extensions: ['dxf'] },
    { mimeType: 'image/vnd.fastbidsheet', extensions: ['fbs'] },
    { mimeType: 'image/vnd.fpx', extensions: ['fpx'] },
    { mimeType: 'image/vnd.fst', extensions: ['fst'] },
    { mimeType: 'image/vnd.fujixerox.edmics-mmr', extensions: ['mmr'] },
    { mimeType: 'image/vnd.fujixerox.edmics-rlc', extensions: ['rlc'] },
    { mimeType: 'image/vnd.microsoft.icon', extensions: ['ico'] },
    { mimeType: 'image/vnd.ms-dds', extensions: ['dds'] },
    { mimeType: 'image/vnd.ms-modi', extensions: ['mdi'] },
    { mimeType: 'image/vnd.ms-photo', extensions: ['wdp'] },
    { mimeType: 'image/vnd.net-fpx', extensions: ['npx'] },
    { mimeType: 'image/vnd.pco.b16', extensions: ['b16'] },
    { mimeType: 'image/vnd.rn-realpix', extensions: ['rp'] },
    { mimeType: 'image/vnd.tencent.tap', extensions: ['tap'] },
    { mimeType: 'image/vnd.valve.source.texture', extensions: ['vtf'] },
    { mimeType: 'image/vnd.wap.wbmp', extensions: ['wbmp'] },
    { mimeType: 'image/vnd.xiff', extensions: ['xif'] },
    { mimeType: 'image/vnd.zbrush.pcx', extensions: ['pcx'] },
    { mimeType: 'image/webp', extensions: ['webp'] },
    { mimeType: 'image/wmf', extensions: ['wmf'] },
    { mimeType: 'image/x-3ds', extensions: ['3ds'] },
    { mimeType: 'image/x-adobe-dng', extensions: ['dng'] },
    { mimeType: 'image/x-applix-graphics', extensions: ['ag'] },
    { mimeType: 'image/x-bmp', extensions: ['bmp', 'dib'] },
    {
      mimeType: 'image/x-bzeps',
      extensions: ['eps.bz2', 'epsi.bz2', 'epsf.bz2'],
    },
    { mimeType: 'image/x-canon-cr2', extensions: ['cr2'] },
    { mimeType: 'image/x-canon-cr3', extensions: ['cr3'] },
    { mimeType: 'image/x-canon-crw', extensions: ['crw'] },
    { mimeType: 'image/x-cdr', extensions: ['cdr'] },
    { mimeType: 'image/x-cmu-raster', extensions: ['ras'] },
    { mimeType: 'image/x-cmx', extensions: ['cmx'] },
    { mimeType: 'image/x-compressed-xcf', extensions: ['xcf.gz', 'xcf.bz2'] },
    { mimeType: 'image/x-dds', extensions: ['dds'] },
    { mimeType: 'image/x-djvu', extensions: ['djvu', 'djv'] },
    { mimeType: 'image/x-emf', extensions: ['emf'] },
    { mimeType: 'image/x-eps', extensions: ['eps', 'epsi', 'epsf'] },
    { mimeType: 'image/x-exr', extensions: ['exr'] },
    { mimeType: 'image/x-fits', extensions: ['fits'] },
    {
      mimeType: 'image/x-freehand',
      extensions: ['fh', 'fhc', 'fh4', 'fh5', 'fh7'],
    },
    { mimeType: 'image/x-fuji-raf', extensions: ['raf'] },
    { mimeType: 'image/x-gimp-gbr', extensions: ['gbr'] },
    { mimeType: 'image/x-gimp-gih', extensions: ['gih'] },
    { mimeType: 'image/x-gimp-pat', extensions: ['pat'] },
    { mimeType: 'image/x-gzeps', extensions: ['eps.gz', 'epsi.gz', 'epsf.gz'] },
    {
      mimeType: 'image/x-icb',
      extensions: ['tga', 'icb', 'tpic', 'vda', 'vst'],
    },
    { mimeType: 'image/x-icns', extensions: ['icns'] },
    { mimeType: 'image/x-ico', extensions: ['ico'] },
    { mimeType: 'image/x-icon', extensions: ['ico'] },
    { mimeType: 'image/x-iff', extensions: ['iff', 'ilbm', 'lbm'] },
    { mimeType: 'image/x-ilbm', extensions: ['iff', 'ilbm', 'lbm'] },
    { mimeType: 'image/x-jng', extensions: ['jng'] },
    { mimeType: 'image/x-jp2-codestream', extensions: ['j2c', 'j2k', 'jpc'] },
    { mimeType: 'image/x-jpeg2000-image', extensions: ['jp2', 'jpg2'] },
    { mimeType: 'image/x-kodak-dcr', extensions: ['dcr'] },
    { mimeType: 'image/x-kodak-k25', extensions: ['k25'] },
    { mimeType: 'image/x-kodak-kdc', extensions: ['kdc'] },
    { mimeType: 'image/x-lwo', extensions: ['lwo', 'lwob'] },
    { mimeType: 'image/x-lws', extensions: ['lws'] },
    { mimeType: 'image/x-macpaint', extensions: ['pntg'] },
    { mimeType: 'image/x-minolta-mrw', extensions: ['mrw'] },
    { mimeType: 'image/x-mrsid-image', extensions: ['sid'] },
    { mimeType: 'image/x-ms-bmp', extensions: ['bmp', 'dib'] },
    { mimeType: 'image/x-msod', extensions: ['msod'] },
    { mimeType: 'image/x-nikon-nef', extensions: ['nef'] },
    { mimeType: 'image/x-nikon-nrw', extensions: ['nrw'] },
    { mimeType: 'image/x-olympus-orf', extensions: ['orf'] },
    { mimeType: 'image/x-panasonic-raw', extensions: ['raw'] },
    { mimeType: 'image/x-panasonic-raw2', extensions: ['rw2'] },
    { mimeType: 'image/x-panasonic-rw', extensions: ['raw'] },
    { mimeType: 'image/x-panasonic-rw2', extensions: ['rw2'] },
    { mimeType: 'image/x-pcx', extensions: ['pcx'] },
    { mimeType: 'image/x-pentax-pef', extensions: ['pef'] },
    { mimeType: 'image/x-photo-cd', extensions: ['pcd'] },
    { mimeType: 'image/x-photoshop', extensions: ['psd'] },
    {
      mimeType: 'image/x-pict',
      extensions: ['pic', 'pct', 'pict', 'pict1', 'pict2'],
    },
    { mimeType: 'image/x-portable-anymap', extensions: ['pnm'] },
    { mimeType: 'image/x-portable-bitmap', extensions: ['pbm'] },
    { mimeType: 'image/x-portable-graymap', extensions: ['pgm'] },
    { mimeType: 'image/x-portable-pixmap', extensions: ['ppm'] },
    { mimeType: 'image/x-psd', extensions: ['psd'] },
    { mimeType: 'image/x-quicktime', extensions: ['qtif', 'qif'] },
    { mimeType: 'image/x-rgb', extensions: ['rgb'] },
    { mimeType: 'image/x-sgi', extensions: ['sgi'] },
    { mimeType: 'image/x-sigma-x3f', extensions: ['x3f'] },
    { mimeType: 'image/x-skencil', extensions: ['sk', 'sk1'] },
    { mimeType: 'image/x-sony-arw', extensions: ['arw'] },
    { mimeType: 'image/x-sony-sr2', extensions: ['sr2'] },
    { mimeType: 'image/x-sony-srf', extensions: ['srf'] },
    { mimeType: 'image/x-sun-raster', extensions: ['sun'] },
    {
      mimeType: 'image/x-targa',
      extensions: ['tga', 'icb', 'tpic', 'vda', 'vst'],
    },
    {
      mimeType: 'image/x-tga',
      extensions: ['tga', 'icb', 'tpic', 'vda', 'vst'],
    },
    { mimeType: 'image/x-win-bitmap', extensions: ['cur'] },
    { mimeType: 'image/x-win-metafile', extensions: ['wmf'] },
    { mimeType: 'image/x-wmf', extensions: ['wmf'] },
    { mimeType: 'image/x-xbitmap', extensions: ['xbm'] },
    { mimeType: 'image/x-xcf', extensions: ['xcf'] },
    { mimeType: 'image/x-xfig', extensions: ['fig'] },
    { mimeType: 'image/x-xpixmap', extensions: ['xpm'] },
    { mimeType: 'image/x-xpm', extensions: ['xpm'] },
    { mimeType: 'image/x-xwindowdump', extensions: ['xwd'] },
    { mimeType: 'image/x.djvu', extensions: ['djvu', 'djv'] },
    { mimeType: 'message/rfc822', extensions: ['eml', 'mime'] },
    { mimeType: 'text/csv', extensions: ['csv'] },
    { mimeType: 'text/markdown', extensions: ['md', 'markdown', 'mkd'] },
    { mimeType: 'text/mathml', extensions: ['mml'] },
    {
      mimeType: 'text/plain',
      extensions: [
        'txt',
        'text',
        'conf',
        'def',
        'list',
        'log',
        'in',
        'ini',
        'asc',
      ],
    },
    { mimeType: 'text/rtf', extensions: ['rtf'] },
    { mimeType: 'text/tab-separated-values', extensions: ['tsv'] },
    { mimeType: 'text/x-comma-separated-values', extensions: ['csv'] },
    { mimeType: 'text/x-csv', extensions: ['csv'] },
    { mimeType: 'text/x-log', extensions: ['log'] },
    { mimeType: 'text/x-markdown', extensions: ['md', 'mkd', 'markdown'] },
    { mimeType: 'text/xml', extensions: ['xml', 'xbl', 'xsd', 'rng'] },
    { mimeType: 'text/yaml', extensions: ['yaml', 'yml'] },
    { mimeType: 'video/3gp', extensions: ['3gp', '3gpp', '3ga'] },
    { mimeType: 'video/3gpp', extensions: ['3gp', '3gpp', '3ga'] },
    { mimeType: 'video/3gpp-encrypted', extensions: ['3gp', '3gpp', '3ga'] },
    { mimeType: 'video/3gpp2', extensions: ['3g2', '3gp2', '3gpp2'] },
    { mimeType: 'video/annodex', extensions: ['axv'] },
    { mimeType: 'video/avi', extensions: ['avi', 'avf', 'divx'] },
    { mimeType: 'video/divx', extensions: ['avi', 'avf', 'divx'] },
    { mimeType: 'video/dv', extensions: ['dv'] },
    { mimeType: 'video/fli', extensions: ['fli', 'flc'] },
    { mimeType: 'video/flv', extensions: ['flv'] },
    { mimeType: 'video/h261', extensions: ['h261'] },
    { mimeType: 'video/h263', extensions: ['h263'] },
    { mimeType: 'video/h264', extensions: ['h264'] },
    { mimeType: 'video/iso.segment', extensions: ['m4s'] },
    { mimeType: 'video/jpeg', extensions: ['jpgv'] },
    { mimeType: 'video/jpm', extensions: ['jpm', 'jpgm'] },
    { mimeType: 'video/mj2', extensions: ['mj2', 'mjp2'] },
    {
      mimeType: 'video/mp2t',
      extensions: [
        'ts',
        'm2t',
        'm2ts',
        'mts',
        'cpi',
        'clpi',
        'mpl',
        'mpls',
        'bdm',
        'bdmv',
      ],
    },
    {
      mimeType: 'video/mp4',
      extensions: ['mp4', 'mp4v', 'mpg4', 'm4v', 'f4v', 'lrv'],
    },
    { mimeType: 'video/mp4v-es', extensions: ['mp4', 'm4v', 'f4v', 'lrv'] },
    {
      mimeType: 'video/mpeg',
      extensions: ['mpeg', 'mpg', 'mpe', 'm1v', 'm2v', 'mp2', 'vob'],
    },
    {
      mimeType: 'video/mpeg-system',
      extensions: ['mpeg', 'mpg', 'mp2', 'mpe', 'vob'],
    },
    { mimeType: 'video/msvideo', extensions: ['avi', 'avf', 'divx'] },
    { mimeType: 'video/ogg', extensions: ['ogv', 'ogg'] },
    { mimeType: 'video/quicktime', extensions: ['mov', 'qt', 'moov', 'qtvr'] },
    { mimeType: 'video/vivo', extensions: ['viv', 'vivo'] },
    { mimeType: 'video/vnd.dece.hd', extensions: ['uvh', 'uvvh'] },
    { mimeType: 'video/vnd.dece.mobile', extensions: ['uvm', 'uvvm'] },
    { mimeType: 'video/vnd.dece.pd', extensions: ['uvp', 'uvvp'] },
    { mimeType: 'video/vnd.dece.sd', extensions: ['uvs', 'uvvs'] },
    { mimeType: 'video/vnd.dece.video', extensions: ['uvv', 'uvvv'] },
    { mimeType: 'video/vnd.divx', extensions: ['avi', 'avf', 'divx'] },
    { mimeType: 'video/vnd.dvb.file', extensions: ['dvb'] },
    { mimeType: 'video/vnd.fvt', extensions: ['fvt'] },
    { mimeType: 'video/vnd.mpegurl', extensions: ['mxu', 'm4u', 'm1u'] },
    { mimeType: 'video/vnd.ms-playready.media.pyv', extensions: ['pyv'] },
    { mimeType: 'video/vnd.radgamettools.bink', extensions: ['bik', 'bk2'] },
    { mimeType: 'video/vnd.radgamettools.smacker', extensions: ['smk'] },
    { mimeType: 'video/vnd.rn-realvideo', extensions: ['rv', 'rvx'] },
    { mimeType: 'video/vnd.uvvu.mp4', extensions: ['uvu', 'uvvu'] },
    { mimeType: 'video/vnd.vivo', extensions: ['viv', 'vivo'] },
    { mimeType: 'video/webm', extensions: ['webm'] },
    { mimeType: 'video/x-anim', extensions: ['anim[1-9j]'] },
    { mimeType: 'video/x-annodex', extensions: ['axv'] },
    { mimeType: 'video/x-avi', extensions: ['avi', 'avf', 'divx'] },
    { mimeType: 'video/x-f4v', extensions: ['f4v'] },
    { mimeType: 'video/x-fli', extensions: ['fli', 'flc'] },
    { mimeType: 'video/x-flic', extensions: ['fli', 'flc'] },
    { mimeType: 'video/x-flv', extensions: ['flv'] },
    { mimeType: 'video/x-javafx', extensions: ['fxm'] },
    { mimeType: 'video/x-m4v', extensions: ['m4v', 'mp4', 'f4v', 'lrv'] },
    { mimeType: 'video/x-matroska', extensions: ['mkv', 'mk3d', 'mks'] },
    { mimeType: 'video/x-matroska-3d', extensions: ['mk3d'] },
    { mimeType: 'video/x-mjpeg', extensions: ['mjpeg', 'mjpg'] },
    { mimeType: 'video/x-mng', extensions: ['mng'] },
    {
      mimeType: 'video/x-mpeg',
      extensions: ['mpeg', 'mpg', 'mp2', 'mpe', 'vob'],
    },
    {
      mimeType: 'video/x-mpeg-system',
      extensions: ['mpeg', 'mpg', 'mp2', 'mpe', 'vob'],
    },
    {
      mimeType: 'video/x-mpeg2',
      extensions: ['mpeg', 'mpg', 'mp2', 'mpe', 'vob'],
    },
    { mimeType: 'video/x-mpegurl', extensions: ['m1u', 'm4u', 'mxu'] },
    { mimeType: 'video/x-ms-asf', extensions: ['asf', 'asx'] },
    { mimeType: 'video/x-ms-asf-plugin', extensions: ['asf'] },
    { mimeType: 'video/x-ms-vob', extensions: ['vob'] },
    { mimeType: 'video/x-ms-wax', extensions: ['asx', 'wax', 'wvx', 'wmx'] },
    { mimeType: 'video/x-ms-wm', extensions: ['wm', 'asf'] },
    { mimeType: 'video/x-ms-wmv', extensions: ['wmv'] },
    { mimeType: 'video/x-ms-wmx', extensions: ['wmx', 'asx', 'wax', 'wvx'] },
    { mimeType: 'video/x-ms-wvx', extensions: ['wvx', 'asx', 'wax', 'wmx'] },
    { mimeType: 'video/x-msvideo', extensions: ['avi', 'avf', 'divx'] },
    { mimeType: 'video/x-nsv', extensions: ['nsv'] },
    { mimeType: 'video/x-ogg', extensions: ['ogv', 'ogg'] },
    { mimeType: 'video/x-ogm', extensions: ['ogm'] },
    { mimeType: 'video/x-ogm+ogg', extensions: ['ogm'] },
    { mimeType: 'video/x-real-video', extensions: ['rv', 'rvx'] },
    { mimeType: 'video/x-sgi-movie', extensions: ['movie'] },
    { mimeType: 'video/x-smv', extensions: ['smv'] },
    { mimeType: 'video/x-theora', extensions: ['ogg'] },
    { mimeType: 'video/x-theora+ogg', extensions: ['ogg'] },
  ]

  static hasMimeType(mimeType) {
    return this.lookup.some(item => item.mimeType === mimeType)
  }
}
