var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"allowance-stats tw-grid tw-grid-cols-3",class:{
    'danger-current': _vm.yearlyAllowances.hasRemainingAllowancesExceeded,
  }},[_c('CurrentAllowance',{attrs:{"allowances":_vm.filteredAllowances,"title":_vm.currentAllowanceTitle},scopedSlots:_vm._u([{key:"extended-title",fn:function(){return [(!_vm.isEmploymentAllowanceUnlimited && !_vm.isRequestedLeave)?_c('span',{staticClass:"tw-bg-gray-350 tw-text-black tw-rounded-md tw-ml-3 tw-py-1 tw-px-2 tw--m-1"},[_vm._v(_vm._s(_vm.summaryView))]):_vm._e(),(
          _vm.isSummaryViewAccrued &&
            !_vm.isEmploymentAllowanceUnlimited &&
            !_vm.isRequestedLeave
        )?_c('ExtraInfo',{staticClass:"tw-my-auto"},[_c('div',{staticClass:"tw-p-4 tw-w-48"},[_vm._v(" Accrued allowance is the portion of total yearly allowance earned up to the last date within this request. "),_c('p',{staticClass:"tw-mt-2"},[_vm._v("Yearly allowance")]),_c('p',[_vm._v(" Before: "+_vm._s(_vm.usedYearlyAllowances)+" "+_vm._s(_vm._f("pluralize")(_vm.yearlyAllowances.allowanceUnit,_vm.usedYearlyAllowances))+" ")]),_c('p',[_vm._v(" After: "+_vm._s(_vm.balanceYearlyAllowances)+" "+_vm._s(_vm._f("pluralize")(_vm.yearlyAllowances.allowanceUnit,_vm.balanceYearlyAllowances))+" ")])])]):_vm._e()]},proxy:true}])}),_c('NewAllowance',{attrs:{"allowances":_vm.filteredAllowances,"title":_vm.newAllowanceTitle},scopedSlots:_vm._u([{key:"extended-title",fn:function(){return [(!_vm.isEmploymentAllowanceUnlimited && !_vm.isRequestedLeave)?_c('span',{staticClass:"tw-bg-gray-350 tw-text-black tw-rounded-md tw-mx-3 tw-py-1 tw-px-2"},[_vm._v(_vm._s(_vm.summaryView))]):_vm._e()]},proxy:true}])}),_c('ChangedAllowance',{attrs:{"allowances":_vm.yearlyAllowances,"icon-classes":"tw-bg-red-100 tw-text-red-700 tw-transform tw-rotate-90"}}),(!_vm.isRequestedLeave && !_vm.isEmploymentAllowanceUnlimited)?_c('Portal',{attrs:{"to":"summary-view-switcher"}},[_c('div',{staticClass:"tw-flex"},[_c('ToggleButton',{attrs:{"labels":true,"sync":true,"color":"#1da1f2"},on:{"change":_vm.changeSummaryView},model:{value:(_vm.isShowAccrued),callback:function ($$v) {_vm.isShowAccrued=$$v},expression:"isShowAccrued"}}),_c('span',{staticClass:"tw-m-1"},[_vm._v("Show accrued")])],1)]):_vm._e(),_c('Portal',{attrs:{"to":"allowance-unlimited-alert"}},[(_vm.isEmploymentAllowanceUnlimited)?_c('BaseAlert',{staticClass:"tw-mt-2",attrs:{"theme":"info"}},[_vm._v(" "+_vm._s(_vm.employmentAllowanceUnlimitedText)+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }