import RequestAllowance from '@/models/employment/RequestAllowance'

export default class LimitedAllowance extends RequestAllowance {
  constructor(adjuster, availableAllowances, isAccrued = false) {
    super(adjuster)

    this.isAccrued = isAccrued
    this.availableAllowances = availableAllowances
  }

  get currentAllowance() {
    return this.adjuster.getCurrentAllowance(
      this.availableAllowances,
      this.isAccrued
    )
  }

  get newAllowance() {
    return this.adjuster.getNewAllowance(
      this.availableAllowances,
      this.isAccrued
    )
  }

  get changedAllowance() {
    return this.adjuster.getChangedAllowance()
  }

  get hasUnlimitedAllowances() {
    return false
  }
}
