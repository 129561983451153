import http from '@/plugins/http'

export default class Company {
  static async update(company, params) {
    return await http.put(`companies/${company.id}`, {
      company_id: company.id,
      ...params,
    })
  }
}
